<template>
  <section class="section">
    <div class="title-container">
      <h2 class="section-title">
        Usługa realizowana z partnerem
      </h2>
    </div>
    <div class="partner-logo">
      <img src="../../../assets/images/logos/atom-store.svg" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PartnerInfo'
}
</script>

<style lang="scss" scoped>
  .partner-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 60px;

    img {
      max-height: 80px;
    }
  }
</style>
